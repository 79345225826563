// Our main CSS
import '../css/app.css'
import '../css/mobile-menu.css'
import '../css/input-fields.css'
import '../css/contact-form.css'
import '../css/sidebar-menu.css'

if (document.getElementById('contactform')) {
  import('./contactform').then((module) => {
    module.default()
  })
}

if (document.querySelector('.js-accordion-button')) {
  import('./accordions').then((module) => {
    module.default()
  })
}

if (document.querySelector('.js-scroll-here')) {
  import('./scroll').then((module) => {
    module.default()
  })
}

if (document.querySelector('.sidebar-menu')) {
  import('./sidebar-menu').then((module) => {
    module.default()
  })
}

if (document.querySelector('.js-open-menu')) {
  import('./mobile-menu').then((module) => {
    module.default()
  })
}

if (document.getElementById('contact-form')) {
  import('./contactform').then((module) => {
    module.default()
  })
}

if (document.querySelector('.js-show-more-files')) {
  const show_more_button = document.querySelector('.js-show-more-files')
  show_more_button.addEventListener('click', () => {
    show_more_button.style.display = 'none'
    document.querySelector('.article-files__more').style.height = 'auto'
  })
}
